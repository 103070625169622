import * as React from "react";

import styled from "styled-components";
import Page from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";

const Heading1 = styled.h1`
  @media (max-width: 1300px) {
    font: normal normal 300 30px/42px "Assistant", sans-serif;
  }
  margin-bottom: 0px;
  margin-top: 40px;
  font: normal normal 300 64px/90px "Assistant", sans-serif;
`;
const Heading2 = styled.h2`
  @media (max-width: 1300px) {
    font: normal normal bold 20px/26px "Assistant", sans-serif;
  }
  margin-bottom: 10px;
  margin-top: 60px;
  color: #32723e;
  font: normal normal bold 32px/42px "Assistant", sans-serif;
`;

const InlineLogo = styled.img`
  @media (max-width: 1300px) {
    width: 200px;
    margin-top: 30px;
  }
  margin-top: 80px;
  max-width: 100%;
`;

const Subtext = styled.div`
  @media (max-width: 1300px) {
    display: none;
  }
  margin-top: 20px;
  font: normal normal 300 30px/42px "Assistant", sans-serif;
`;
const SubtextMobile = styled.div`
  @media (max-width: 1300px) {
    display: block;
  }
  display: none;
  margin-top: 20px;
  font: normal normal 300 18px/26px "Assistant", sans-serif;
`;

const SoMe = styled.div`
  @media (max-width: 1300px) {
    margin-top: 32px;
  }
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Item = styled.a`
  @media (max-width: 1300px) {
    width: 44px;
    margin-right: 22px;
  }
  margin-right: 30px;
  text-decoration: none;
  img {
    max-width: 100%;
  }
`;

const IndexPage = () => (
  <DefaultLayout>
    <Page>
      <Container>
        <Heading1>Lösungen für eine vernetzte Welt</Heading1>
        <Heading2>Über nextra.ch</Heading2>
        <p>
          Wir sind ein Team von IT- und Kommunikationsexperten mit vielfältigem
          Fachwissen. Unser Ziel ist es, auch anspruchsvolle Anforderungen
          sicher und kompetent zu bewältigen, damit die Nutzung unserer Lösungen
          einfach, verständlich und vor allem sorgenfrei ist. Hohe
          Verfügbarkeit, flexible Umsetzung und unkomplizierte Projektbegleitung
          gewährleisten eine dynamische und verlässliche Zusammenarbeit auf
          Augenhöhe.
        </p>
        <Heading2>Tätigkeitsgebiete</Heading2>
        <p>
          Engineering, Betrieb und Monitoring sind unsere Hauptdisziplinen.
          Security, Skalierbarkeit, Zukunftssicherheit und Erweiterbarkeit sind
          für uns selbstverständlich.
        </p>
        <Heading2>Netzwerk</Heading2>
        <p>
          Für uns bedeutet Netzwerk weit mehr als nur die Verbindung von
          Systemen. Es steht für gute Beziehungen zu unserem Umfeld, Partnern,
          Lieferanten und Unterstützern. Auch in komplexen technischen
          Angelegenheiten bilden Dialog, Vertrauen und Offenheit die Grundlage
          für das gemeinsame Erreichen der definierten Ziele.
        </p>
        <InlineLogo src="/static/logo-green.svg" alt="" />
        <Subtext>
          nextra.ch • Seestrasse 113a • CH-9326 Horn • info@nextra.ch
        </Subtext>
        <SubtextMobile>
          nextra.ch
          <br />
          Seestrasse 113a
          <br />
          CH-9326 Horn
          <br />
          info@nextra.ch
        </SubtextMobile>
        <SoMe>
          <Item href="https://www.linkedin.com/company/nextra-ch">
            <img src="/static/linkedin.svg" alt="Linkedin" />
          </Item>
          <Item href="https://x.com/nextra_ch">
            <img src="/static/x.svg" alt="X" />
          </Item>
          <Item href="http://fb.com/nextra.ch.official">
            <img src="/static/fb.svg" alt="facebook" />
          </Item>
        </SoMe>
      </Container>
    </Page>
  </DefaultLayout>
);

export default IndexPage;
